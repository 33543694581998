import { Outlet } from "@remix-run/react";
import { ClientOnly } from "remix-utils/client-only";
import { DevTools } from "~/components/dev-tools/dev-tools";

export default function Index() {
  return (
    <div>
      <ClientOnly>{() => <DevTools />}</ClientOnly>
      <Outlet />
    </div>
  );
}
