import { useHref } from "@remix-run/react";
import { useRef } from "react";
import { trpc } from "~/utils/trpc";

export function DevTools() {
  if (window.ENV.APP_ENV === "production") {
    return null;
  }
  return (
    <div className="fixed inset-x-0 bottom-0 z-50 flex h-10 items-center divide-y divide-neutral-800 bg-neutral-900 px-3 font-mono text-xs uppercase text-neutral-400">
      <DevUserSwitcher />
    </div>
  );
}

export const DevUserSwitcher = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const action = useHref("/dev/auth");

  const users = trpc.dev.users.all.useQuery();
  const me = trpc.public.me.useQuery();
  console.log({ users, me });
  return (
    <form ref={formRef} action={action} method="post" className="flex items-center space-x-2">
      <label>User</label>
      {me.isLoading && <span>Loading...</span>}

      {users.data ? (
        <select
          value={me?.data?.id}
          name="id"
          className="bg-transparent text-neutral-200"
          onChange={() => formRef.current?.submit()}
        >
          <option value="">None</option>
          {users.data?.map((user) => (
            <option key={user.id} value={user.id}>
              {user.username}
            </option>
          ))}
        </select>
      ) : null}
    </form>
  );
};
